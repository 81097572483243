import React from "react";
import style from './index.module.less'
import useNavigate from '@/hooks/useNavigate'
import {
    beamApp_video
} from '@/images/air2/index'
import { useIntl } from "react-intl";
import { symbol } from "prop-types";
const BeamIntro = ({ ifShow }) => {
    const intl = useIntl()
    return (
        <div className={style.beamBox}>
            <h2 className={style.beamBoxTitle}>{intl.formatMessage({ id: "air2.powerDuo.title" })}</h2>
            <div className={style.beamBoxDesc}
                dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `air2.powerDuo.desc` })}` }} ></div>
            <div className={style.beamBoxLink} onClick={() => useNavigate('/beam', true)}>
                {intl.formatMessage({ id: "air2.powerDuo.link" })}
            </div>
            <div style={{ padding: "0 20px" }}>
                <div className={style.beamBoxVideo}>
                    {ifShow && <video autoPlay playsInline loop muted width="100%">
                        <source src={beamApp_video} type="video/mp4" />
                    </video>}
                </div>
            </div>



        </div>
    )
}

export default BeamIntro