import React, { useEffect } from "react";
import style from './index.module.less'
import {
    gaming_glass,
    gaming_ally,
    gaming_xbox,
    gaming_steam,
    gaming_switch,
    gaming_ps5,
} from '@/images/air2/index'
import { gsap, Power1 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import classNames from "classnames";
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl"

const Gaming = () => {
    gsap.registerPlugin(ScrollTrigger)
    const ifPc = useBigScreen(768)
    const intl = useIntl()

    useEffect(() => {
        gsap.killTweensOf('.gaming-out');
        gsap.killTweensOf('.gaming-inner');
        gsap.killTweensOf('.gaming-glass');
        gsap.killTweensOf('.gaming-mid');
        if (ifPc) {

            const outBoxes = gsap.utils.toArray('.gaming-out');
            outBoxes.forEach(item => {
                gsap.to(item, {
                    bottom: '0px',
                    scrollTrigger: {
                        start: "top top",
                        end: "bottom bottom",
                        scrub: true,
                        trigger: '.gaming-box',

                    }
                })
            });
            const innerBoxes = gsap.utils.toArray('.gaming-inner');
            innerBoxes.forEach(item => {
                gsap.to(item, {
                    bottom: '18%',
                    scrollTrigger: {
                        start: "top top",
                        end: "bottom bottom",
                        scrub: true,
                        trigger: '.gaming-box',

                    }
                })
            });
            gsap.fromTo('.gaming-glass', { top: '40%' }, {
                top: '2%',
                scrollTrigger: {
                    start: "top top",
                    end: "bottom bottom",
                    scrub: true,
                    trigger: '.gaming-box',

                }
            })
            gsap.to('.gaming-mid', {
                bottom: '33%',
                scrollTrigger: {
                    start: "top top",
                    end: "bottom bottom",
                    scrub: true,
                    trigger: '.gaming-box',

                }
            })
        } else {


            const outBoxes = gsap.utils.toArray('.gaming-out');
            outBoxes.forEach(item => {
                gsap.to(item, {
                    bottom: "7%",
                    scrollTrigger: {
                        start: "top top",
                        end: "bottom bottom",
                        scrub: true,
                        trigger: '.gaming-box',

                    }
                })
            });
            const innerBoxes = gsap.utils.toArray('.gaming-inner');
            innerBoxes.forEach(item => {
                gsap.to(item, {
                    bottom: '31%',
                    scrollTrigger: {
                        start: "top top",
                        end: "bottom bottom",
                        scrub: true,
                        trigger: '.gaming-box',

                    }
                })
            });
            gsap.fromTo('.gaming-glass', { top: "20%" }, {
                top: '2%',
                scrollTrigger: {
                    start: "top top",
                    end: "bottom bottom",
                    scrub: true,
                    trigger: '.gaming-box',

                }
            })
            gsap.to('.gaming-mid', {
                bottom: '46%',
                scrollTrigger: {
                    start: "top top",
                    end: "bottom bottom",
                    scrub: true,
                    trigger: '.gaming-box',

                }
            })
        }

    }, [ifPc])
    return (
        <div className={style.gaming}>
            <h2 className={style.gamingTitle} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `air2.refreshrate.t` })}` }}></h2>
            <div className={style.gamingDesc}>
               {intl.formatMessage({ id: "air2.refreshrate.comments"}) }
            </div>
            <div className={classNames(style.gamingBox, 'gaming-box')}>
                <div className={style.gamingBoxDevice}>
                    <img src={gaming_glass} className={classNames(style.gamingBoxGlass, 'gaming-glass')} />
                    <img src={gaming_xbox} className={classNames(style.gamingBoxXbox, 'gaming-out')} />
                    <img src={gaming_ps5} className={classNames(style.gamingBoxPS5, 'gaming-out')} />
                    <img src={gaming_switch} className={classNames(style.gamingBoxSwitch, 'gaming-inner')} />
                    <img src={gaming_steam} className={classNames(style.gamingBoxSteam, 'gaming-inner')} />
                    <img src={gaming_ally} className={classNames(style.gamingBoxAlly, 'gaming-mid')} />

                </div>

            </div>
        </div>
    )
}

export default Gaming