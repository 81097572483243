import React from 'react'
import style from './index.module.less'
import {
    immersionVideo,
    immersion_level1,
    immersion_level2,
    immersion_level3,
} from '@/images/air2/index'
import { useIntl } from "react-intl"

const Immersion = ({ifShow}) => {
    const intl = useIntl()

    const introArr=[
        {
            img:immersion_level1,
            level:"LEVEL 1",
            desc:intl.formatMessage({ id: "air2.electrochromic.level1" }),
        },
        {
            img:immersion_level2,
            level:"LEVEL 2",
            desc:intl.formatMessage({ id: "air2.electrochromic.level2" }),
        },
        {
            img:immersion_level3,
            level:"LEVEL 3",
            desc:intl.formatMessage({ id: "air2.electrochromic.level3" }),
        }
    ]
    return (
        <div className={style.immersion}>
            <div className={style.immersionText}>
                <h2 className={style.immersionTextTitle}> {intl.formatMessage({ id: "air2.electrochromic.t" })}</h2>
                <div className={style.immersionTextDesc} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `air2.electrochromic.comments`})}` }}></div>
                <div className={style.immersionTextTip}>{intl.formatMessage({ id: "air2.electrochromic.tips" })}</div>
            </div>
            <div className={style.immersionVideo}>
                {ifShow&&<video autoPlay playsInline loop muted width="100%" >
                    <source src={immersionVideo} type="video/mp4" />
                </video>}
                
            </div>
            <div className={style.immersionIntro}>
                {introArr.map((item,index)=>(
                    <div className={style.immersionIntroItem} key={`immersionIntroItem${index}`}>
                        <img src={item.img} />
                        <div className={style.immersionIntroItemLevel}>
                            {item.level}
                        </div>
                        <div className={style.immersionIntroItemDesc}>{item.desc}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Immersion