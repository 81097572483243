import React, { useEffect, useState } from "react";
import { Layout, SEO, ProFirst, RedBuyBtn, TwoPics, TurnDevices, ProcessNav } from '@/components/index'
import { buy_link, spec_price } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import {
    proBg_air2_pc,
    proBg_air2_mobile,
    pro_air2_logo,
    env_air2_1,
    env_air2_2,
    env_air2_3,
    env_air2_4,
    env_air2_5,
    env_air2_6,
    env_air2_7,
    env_air2_8,
    env_air2_9,
    env_air2_10,
    proBg_air2_pc_right
} from '@/images/air2/index'

import {
    Gaming,
    CertificatedBox,
    Upgrade,
    Beauty,
    Lense,
    Air2Buy,
    CinematicSound,
    Immersion,
    Color,
    MicroOled,
    Engine,
    DisplayModes,
    Spec,
    BeamIntro
} from '@/components/Air2'
import {
    specs_air2pro, specs_air2, specs_air
} from '@/images/air2/index'
import { useIntl } from "react-intl"
import { useInView } from "react-intersection-observer"
import { useGTM } from '@/hooks/useGTM.js'

const Index = () => {
    const [ifRender, setIfRender] = useState(false)

    const [headerRef, headerRefInView] = useInView({ threshold: 0 });
    const [microOledRef, microOledRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [twoPics1Ref, twoPics1RefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [engineRef, engineRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [colorRef, colorRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [immersionRef, immersionRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [cinematicRef, cinematicRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [twoPics2Ref, twoPics2RefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [gamingRef, gamingRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [twoPics3Ref, twoPics3RefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [twoPics4Ref, twoPics4RefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [twoPics5Ref, twoPics5RefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [certificatedRef, certificatedRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [upgradeRef, upgradeRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [beautyRef, beautyRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [displayRef, displayRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [turnRef, turnRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [lenseRef, lenseRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [specRef, specRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [beamRef, beamRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const countryCode = getCountry()

    const intl = useIntl()
    const specList = [
        {
            header: {
                img: specs_air2pro,
                colors: ['#414141'],
                name: 'Air 2 Pro',
                desc: intl.formatMessage({ id: "specs.air2pro.desc" }),
                price: spec_price[countryCode]['air2pro'],
                router: 'air2',
                shop: buy_link[countryCode]['air2pro'].shop || buy_link[countryCode]['air2pro'].amazon,
                pro_name:"air2pro",
                shop_sale:!!buy_link[countryCode]['air2pro'].sale_shop_price?buy_link[countryCode]['air2pro'].sale_shop_price:false
            },
            list: [
                {
                    label: intl.formatMessage({ id: "specs.air2pro.label01" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text01" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label02" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text02" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label03" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text03" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label04" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text04" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label05" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text05" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label06" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text06" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label07" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text07" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label08" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text08" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label09" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text09" }),
                }
            ]
        },
        {
            header: {
                img: specs_air2,
                colors: ['#FF1B12', '#414141'],
                name: 'Air 2',
                desc: intl.formatMessage({ id: "specs.air2.desc" }),
                price: spec_price[countryCode]['air2'],
                router: 'air2',
                shop: buy_link[countryCode]['air2'].shop || buy_link[countryCode]['air2'].amazon,
                pro_name:"air2",
                shop_sale:!!buy_link[countryCode]['air2'].sale_shop_price?buy_link[countryCode]['air2'].sale_shop_price:false
            },
            list: [
                {
                    label: intl.formatMessage({ id: "specs.air2pro.label01" }),
                    text: intl.formatMessage({ id: "specs.air2.text01" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label02" }),
                    text: intl.formatMessage({ id: "specs.air2.text02" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label03" }),
                    text: intl.formatMessage({ id: "specs.air2.text03" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label04" }),
                    text: intl.formatMessage({ id: "specs.air2.text04" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label05" }),
                    text: intl.formatMessage({ id: "specs.air2.text05" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label06" }),
                    text: intl.formatMessage({ id: "specs.air2.text06" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label07" }),
                    text: intl.formatMessage({ id: "specs.air2.text07" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label08" }),
                    text: intl.formatMessage({ id: "specs.air2.text08" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label09" }),
                    text: intl.formatMessage({ id: "specs.air2.text09" }),
                }
            ]
        },
        {
            header: {
                img: specs_air,
                colors: ['#414141'],
                name: 'Air',
                desc: intl.formatMessage({ id: "specs.air.desc" }),
                price: spec_price[countryCode]['air'],
                router: 'air',
                shop: buy_link[countryCode]['air'].shop || buy_link[countryCode]['air'].amazon,
                pro_name:"air",
                shop_sale:!!buy_link[countryCode]['air'].sale_shop_price?buy_link[countryCode]['air'].sale_shop_price:false

            },
            list: [
                {
                    label: intl.formatMessage({ id: "specs.air2pro.label01" }),
                    text: intl.formatMessage({ id: "specs.air.text01" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label02" }),
                    text: intl.formatMessage({ id: "specs.air.text02" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label03" }),
                    text: intl.formatMessage({ id: "specs.air.text03" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label04" }),
                    text: intl.formatMessage({ id: "specs.air.text04" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label05" }),
                    text: intl.formatMessage({ id: "specs.air.text05" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label06" }),
                    text: intl.formatMessage({ id: "specs.air.text06" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label07" }),
                    text: intl.formatMessage({ id: "specs.air.text07" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label08" }),
                    text: intl.formatMessage({ id: "specs.air.text08" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label09" }),
                    text: intl.formatMessage({ id: "specs.air.text09" }),
                }
            ]
        },
    ]
    const proFirstMsg = {
        textBlack: false,
        desc: intl.formatMessage({ id: "air2.one2all" }),
        logo: pro_air2_logo,
        backgroundColor: "#000",
        pcImg: proBg_air2_pc_right,
        mobileImg: proBg_air2_mobile,
        title: ""
    }
    const twoPics_arr1 = [
        {
            img: env_air2_1,
            desc: intl.formatMessage({ id: "air2.330" })
        },
        {
            img: env_air2_2,
            desc: intl.formatMessage({ id: "air2.freedom" })
        }
    ]
    const twoPics_arr2 = [
        {
            img: env_air2_3,
            desc: intl.formatMessage({ id: "air2.Theatric" })
        },
        {
            img: env_air2_4,
            desc: intl.formatMessage({ id: "air2.Turninto" })
        }
    ]
    const twoPics_arr3 = [
        {
            img: env_air2_5,
            desc: intl.formatMessage({ id: "air2.Playinggames" })
        },
        {
            img: env_air2_6,
            desc: ' '
        }
    ]
    const twoPics_arr4 = [
        {
            img: env_air2_7,
            desc: intl.formatMessage({ id: "air2.nopain" })
        },
        {
            img: env_air2_8,
            desc: " "
        }
    ]
    const twoPics_arr5 = [
        {
            img: env_air2_9,
            desc: intl.formatMessage({ id: "air2.amonitor" })
        },
        {
            img: env_air2_10,
            desc: " "
        }
    ]
    const navList = [
        {
            id: 0,
            title: intl.formatMessage({ id: "air2.process.title1", defaultMessage: "Air 2 Series" }),
            classname: "air2-air2",
        },
        {
            id: 1,
            title: intl.formatMessage({ id: "air2.process.title2", defaultMessage: "Display" }),
            classname: "air2-display",
        },
        {
            id: 2,
            title: intl.formatMessage({ id: "air2.process.title3", defaultMessage: "Color Accuracy" }),
            classname: "air2-color",
        },
        {
            id: 3,
            title: intl.formatMessage({ id: "air2.process.title4", defaultMessage: "Immersion Control" }),
            classname: "air2-immersion",
        },
        {
            id: 4,
            title: intl.formatMessage({ id: "air2.process.title5", defaultMessage: "Cinematic Sound" }),
            classname: "air2-cinematic",
        },
        {
            id: 5,
            title: intl.formatMessage({ id: "air2.process.title6", defaultMessage: "The Power Duo" }),
            classname: "air2-power",
        },
        {
            id: 6,
            title: intl.formatMessage({ id: "air2.process.title7", defaultMessage: "120Hz" }),
            classname: "air2-120hz",
        },
        {
            id: 7,
            title: intl.formatMessage({ id: "air2.process.title8", defaultMessage: "Health" }),
            classname: "air2-health",
        }, {
            id: 8,
            title: intl.formatMessage({ id: "air2.process.title9", defaultMessage: "Ergonomic Design" }),
            classname: "air2-ergonomic"
        },
        {
            id: 9,
            title: intl.formatMessage({ id: "air2.process.title10", defaultMessage: "Compatibility" }),
            classname: "air2-compatibility"
        }, {
            id: 10,
            title: intl.formatMessage({ id: "air2.process.title11", defaultMessage: "Display Modes" }),
            classname: "air2-display2"
        }
        , {
            id: 11,
            title: intl.formatMessage({ id: "air2.process.title12", defaultMessage: "Prescription lenses" }),
            classname: "air2-prescription"
        }
        , {
            id: 12,
            title: intl.formatMessage({ id: "air2.process.title13", defaultMessage: "Comparison" }),
            classname: "air2-comparison"
        }
    ]

    useEffect(() => {
        if (['kr', 'th'].indexOf(window.location.pathname.split('/')[1]) > -1) {
            setIfRender(false)
        } else {
            setIfRender(true)
        }
    }, [])

 

    return (

        <Layout
            showsubmenu={true}
            menuBlack={!headerRefInView ? true : false}
        >
            <SEO page="Air2" />
            <div style={{ position: "relative" }}>
                {ifRender && <RedBuyBtn cate='air2' gaName='Air 2 / Pro' />}
                <ProcessNav list={navList} />
                <div className='air2-air2' >
                    <div ref={headerRef}>
                        <ProFirst msg={proFirstMsg} />
                    </div>
                    <div ref={microOledRef}><MicroOled /></div>
                    <div ref={twoPics1Ref}><TwoPics data={twoPics_arr1} ifShow={microOledRefInView || engineRefInView} /></div>
                </div>
                <div className='air2-display'>
                    <div ref={engineRef}><Engine ifShow={twoPics1RefInView || colorRefInView} /></div>
                </div>
                <div className='air2-color'>
                    <div ref={colorRef}><Color ifShow={engineRefInView || immersionRefInView} /></div>
                </div>
                <div className='air2-immersion'>
                    <div ref={immersionRef}><Immersion ifShow={colorRefInView || cinematicRefInView} /></div>
                </div>
                <div className='air2-cinematic'>
                    <div ref={cinematicRef}><CinematicSound ifShow={immersionRefInView || beamRefInView} /></div>
                </div>
                <div className='air2-power'>
                    <div ref={beamRef}>
                        {countryCode !== 'jp' && <BeamIntro ifShow={cinematicRefInView || twoPics2RefInView} />}
                    </div>
                    <div ref={twoPics2Ref}>  <TwoPics data={twoPics_arr2} ifShow={beamRefInView || gamingRefInView} /></div>
                </div>
                <div className="air2-120hz">
                    <div ref={gamingRef}><Gaming /></div>
                    <div ref={twoPics3Ref}> <TwoPics data={twoPics_arr3} ifShow={gamingRefInView || certificatedRefInView} /></div>
                </div>
                <div className='air2-health'>
                    <div ref={certificatedRef}><CertificatedBox ifShow={twoPics3RefInView || twoPics4RefInView} /></div>
                    <div ref={twoPics4Ref}> <TwoPics data={twoPics_arr4} ifShow={certificatedRefInView || upgradeRefInView} /></div>
                </div>
                <div className='air2-ergonomic'>
                    <div ref={upgradeRef}><Upgrade ifShow={twoPics4RefInView || beautyRefInView} /></div>
                    <div ref={beautyRef}><Beauty ifShow={upgradeRefInView || twoPics5RefInView} /></div>
                    <div ref={twoPics5Ref}> <TwoPics data={twoPics_arr5} ifShow={beautyRefInView || turnRefInView} /></div>
                </div>
                <div className='air2-compatibility'>
                    <div ref={turnRef}><TurnDevices ifShow={twoPics5RefInView || displayRefInView} midDevice={'air2'} /></div>
                </div>
                <div className="air2-display2">
                    <div ref={displayRef}><DisplayModes ifShow={turnRefInView || lenseRefInView} /></div>
                </div>
                <div className='air2-prescription'>
                    <div ref={lenseRef}><Lense ifShow={displayRefInView || specRefInView} /></div>
                </div>
                <div className='air2-comparison'>
                    <div ref={specRef}> <Spec list={specList} cate='Air2' /></div>
                    {ifRender && <Air2Buy />}
                </div>

            </div>

        </Layout>




    )
}


export default Index








