import React from 'react'
import style from './index.module.less'
import {lifeLikeColor} from '@/images/air2/index'
import { useIntl } from "react-intl"
const Color = ({ifShow}) => {
    const intl = useIntl()
    return (
        <div className={style.color}>
            <div className={style.colorText}>
                <h2 className={style.colorTextTitle}>
                {intl.formatMessage({ id: "air2.color.t" })}
                  <br />
                  <span> {intl.formatMessage({ id: "air2.color.subt" })}</span>
                </h2>
                <div className={style.colorTextDesc}>{intl.formatMessage({ id: "air2.color.comments" })}</div>
            </div>
            <div className={style.colorImg} style={{backgroundImage:`url(${ifShow?lifeLikeColor:""})`}}>

            </div>
        </div>
    )
}

export default Color