/**
 * 传入三个参数
 * speed 视差速度 默认200
 * background 背景图
 * styles传入盒子宽高 圆角等样式 默认全屏宽高
 */

import React, { useEffect, useRef } from "react";
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
const Parallax = ({ speed, background,styles }) => {
    gsap.registerPlugin(ScrollTrigger)
    const parallaxBoxRef = useRef()
    const parallaxRef = useRef()
    useEffect(() => {
            gsap.to(parallaxBoxRef.current, {
                top: 0,
                scrollTrigger: {
                    start: "top bottom",
                    end: "bottom top",
                    trigger: parallaxRef.current,
                    scrub: true,
                }
            });

    }, [])
    return (
        <div className={style.parallax} ref={parallaxRef} style={styles}>
            <div className={style.parallaxBox}
                style={{
                    backgroundImage: `url(${background})`,
                    height: `calc(100% + ${!!speed?speed:200}px)`,
                    top:`-${speed?speed:200}px`
                }}
                ref={parallaxBoxRef}
            >

            </div>

        </div>

    )
}

export default Parallax