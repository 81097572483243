import React from "react";
import style from './index.module.less'
import { useIntl } from "react-intl"
import {
    certificated_bg, 
    certificated1,
    certificated2,
} from '@/images/air2/index'
const CertificatedBox = ({ifShow}) => {
    const intl = useIntl()
    const iconArr = [certificated1, certificated2]
    return (
        <div className={style.certificated}>
            <h2 className={style.certificatedTitle}>{intl.formatMessage({ id: "air2.design.t" })}</h2>
            <div className={style.certificatedDesc}>{intl.formatMessage({ id: "air2.design.subt" })}</div>
            <div className={style.certificatedTip}>{intl.formatMessage({ id: "air2.design.comments" })}</div>
            <div className={style.certificatedBox}  style={{backgroundImage:`url(${ifShow?certificated_bg:""})`}}>
                <h2 className={style.certificatedBoxTitle}>TÜV Rheinland</h2>
                <div className={style.certificatedBoxDesc}>Certificated</div>
                <div className={style.certificatedBoxIcon}>
                    {iconArr.map((item, index) => (
                        <img src={item} key={`certificated${index}`} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CertificatedBox