import React, { useEffect } from "react";
import classNames from 'classnames'
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {

    micro_bg3,
} from '@/images/air2/index'
import { useIntl } from "react-intl"

const Index = () => {
    const intl = useIntl()

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        gsap.to('.microPageInner', {
            scrollTrigger: {
                trigger: '.microPage',
                start: 'top top',
                end: 'bottom 130%',
                scrub: true,
                onEnterBack: () => {
                    gsap.to('.pageContent', {
                        opacity: 0,
                        duration: 1
                    });
                },
                onLeave: () => {
                    gsap.to('.pageContent', {
                        opacity: 1,
                        duration: 1
                    });
                }
            }
        });
    }, [])

    return (
        <div className={classNames(style.page, 'microPage')}>
            <div className={style.pageSticky}>
           
                <div className={classNames(style.pageInner, 'microPageInner')} style={{ backgroundImage: `url(${micro_bg3})` }}></div>
                <div className={classNames(style.pageContent, 'pageContent')}>
                    <h2 className={style.pageContentTitle}>{intl.formatMessage({ id: "air2.fromsony" })}</h2>
                    <div className={style.pageContentIntro}>
                        <h4 className={style.pageContentIntroItem}>{intl.formatMessage({ id: "air2.sony_advantage1" })}</h4>
                        <h4 className={style.pageContentIntroItem}>{intl.formatMessage({ id: "air2.sony_advantage2" })}</h4>
                        <h4 className={style.pageContentIntroItem}>{intl.formatMessage({ id: "air2.sony_advantage3" })}</h4>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Index