import React from 'react'
import style from './index.module.less'
import { Parallax } from '@/components/index'
import useBigScreen from "@/hooks/bigScreen"

const TwoPics = ({ data,ifShow=true }) => {
    const ifPc = useBigScreen(768)
 
    return (
        <div className={style.twopic} >
            <div className={style.picBox}>
                {data.map((item, index) => (
                    <div className={style.picBoxItem} key={`twoPicsItem${index}`}>
                        <h2 className={style.picBoxItemDesc}>{item.desc}</h2>
                        <div className={style.picBoxItemImg}>
                             {ifShow&&<Parallax background={item.img} speed={ifPc?200:150} styles={{ aspectRatio: "1/1", height: "auto" }} />}
                        </div>
                       
                        

                    </div>
                ))}

            </div>

        </div>

    )
}

export default TwoPics
