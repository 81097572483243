import React from 'react'
import style from './index.module.less'
import {
    cinematicSoundL,
    cinematicSoundR,
} from '@/images/air2/index'
import { useIntl } from "react-intl"

const CinematicSound = ({ifShow}) => {
    const intl = useIntl()

    return (
        <div className={style.sound}>
            <div className={style.soundText}>
                <h2 className={style.soundTextTitle}> {intl.formatMessage({ id: "air2.Sound.t" })}</h2>
                <div className={style.soundTextDesc}>
                    {intl.formatMessage({ id: "air2.Sound.des" })}
                </div>
                <div className={style.soundTextTip}>                {intl.formatMessage({ id: "air2.Sound.comments" })}
                </div>
            </div>
            <div className={style.soundImg} >
                <div className={style.soundImgL} style={{ backgroundImage: `url(${ifShow?cinematicSoundL:""})` }}></div>
                <div className={style.soundImgR} style={{ backgroundImage: `url(${ifShow?cinematicSoundR:""})` }}></div>

            </div>
        </div>
    )
}

export default CinematicSound