import React, { useState } from "react";
import classNames from 'classnames'
import style from './index.module.less'
import useBigScreen from "@/hooks/bigScreen"
import {

    displayMode_glasses,
    displayMode_beam,
    displayMode_add,
    displayVideo1,
    displayVideo2,
    displayVideo3,
    displayVideo4
} from '@/images/air2/index'
import { useIntl } from "react-intl"
const Index = ({ifShow}) => {
    const intl = useIntl()
    const [activeIdx, setActiveIdx] = useState(null);
    const data = [{
        img: displayVideo1,
        title: intl.formatMessage({ id: "display.aircasting.title" }),
        device: [displayMode_glasses],
        list: [
            [{
                label: intl.formatMessage({ id: "display.aircasting.label1" }),
                text: intl.formatMessage({ id: "display.aircasting.text1" }),
            }, {
                label: intl.formatMessage({ id: "display.aircasting.label2" }),
                text: intl.formatMessage({ id: "display.aircasting.text2" }),
            }],
            [{
                label: intl.formatMessage({ id: "display.aircasting.label3" }),
                text: intl.formatMessage({ id: "display.aircasting.text3" }),
            }, {
                label: intl.formatMessage({ id: "display.aircasting.label4" }),
                text: intl.formatMessage({ id: "display.aircasting.text4" }),
            }],
            [{
                label: intl.formatMessage({ id: "display.aircasting.label5" }),
                text: intl.formatMessage({ id: "display.aircasting.text5" }),
            }]]
    }, {
        img: displayVideo2,
        title: intl.formatMessage({ id: "display.bodyanchor.title" }),
        device: [displayMode_glasses, displayMode_add, displayMode_beam],
        list: [
            [{
                label: intl.formatMessage({ id: "display.aircasting.label1" }),
                text: intl.formatMessage({ id: "display.bodyanchor.text1" }),
            }, {
                label: intl.formatMessage({ id: "display.aircasting.label2" }),
                text: intl.formatMessage({ id: "display.bodyanchor.text2" }),
            }],
            [{
                label: intl.formatMessage({ id: "display.aircasting.label3" }),
                text: intl.formatMessage({ id: "display.bodyanchor.text3" }),
            }, {
                label: intl.formatMessage({ id: "display.aircasting.label4" }),
                text: intl.formatMessage({ id: "display.bodyanchor.text4" }),
            }],
            [{
                label: intl.formatMessage({ id: "display.aircasting.label5" }),
                text: intl.formatMessage({ id: "display.bodyanchor.text5" }),
            }]]
    }, {
        img: displayVideo3,
        title: intl.formatMessage({ id: "display.smoothfollow.title" }),
        device: [displayMode_glasses, displayMode_add, displayMode_beam],
        list: [
            [{
                label: intl.formatMessage({ id: "display.aircasting.label1" }),
                text: intl.formatMessage({ id: "display.smoothfollow.text1" }),
            }, {
                label: intl.formatMessage({ id: "display.aircasting.label2" }),
                text: intl.formatMessage({ id: "display.smoothfollow.text2" }),
            }],
            [{
                label: intl.formatMessage({ id: "display.aircasting.label3" }),
                text: intl.formatMessage({ id: "display.smoothfollow.text3" }),
            }, {
                label: intl.formatMessage({ id: "display.aircasting.label4" }),
                text: intl.formatMessage({ id: "display.smoothfollow.text4" }),
            }],
            [{
                label: intl.formatMessage({ id: "display.aircasting.label5" }),
                text: intl.formatMessage({ id: "display.smoothfollow.text5" }),
            }]]
    }, {
        img: displayVideo4,
        title: intl.formatMessage({ id: "display.sideview.title" }),
        device: [displayMode_glasses, displayMode_add, displayMode_beam],
        list: [
            [{
                label: intl.formatMessage({ id: "display.aircasting.label1" }),
                text: intl.formatMessage({ id: "display.sideview.text1" }),
            }, {
                label: intl.formatMessage({ id: "display.aircasting.label2" }),
                text: intl.formatMessage({ id: "display.sideview.text2" }),
            }],
            [{
                label: intl.formatMessage({ id: "display.aircasting.label3" }),
                text: intl.formatMessage({ id: "display.sideview.text3" }),
            }, {
                label: intl.formatMessage({ id: "display.aircasting.label4" }),
                text: intl.formatMessage({ id: "display.sideview.text4" }),
            }],
            [{
                label: intl.formatMessage({ id: "display.aircasting.label5" }),
                text: intl.formatMessage({ id: "display.sideview.text5" }),
            }]]
    }]

    const bigScreen = useBigScreen()


    const handleMouseEnter = (index) => {
        if (bigScreen) {
            setActiveIdx(index)
        }
    }
    const handleMouseLeave = (index) => {
        if (bigScreen) {
            setActiveIdx(null)
        }
    }

    return (
        <div className={style.page}>
            <h2 className={style.pageTitle}>Display Modes</h2>
            {/* <h4 className={style.pageSubtitle}>Display Modes instructions Display Modes instructions Display Modes instructions Display Modes instructions</h4> */}
            <div className={style.pageBox}>
                {data?.map((item, index) => (
                    <div className={classNames(style.pageBoxItem, activeIdx == index ? style.active : (activeIdx != null && activeIdx != index) ? style.other : '')} key={item.title}>
                        <div className={style.pageBoxItemTop} onMouseEnter={() => { handleMouseEnter(index) }} onMouseLeave={() => { handleMouseLeave(index) }}>
                            {ifShow&&<video autoPlay playsInline loop muted width="100%" className={classNames(style.pageBoxItemTopImg)}>
                                <source src={item.img} type="video/mp4" />
                            </video>}
                            
                            {/* <img className={classNames(style.pageBoxItemTopImg)} src={item.img} draggable={false} /> */}
                            <h3 className={style.pageBoxItemTopTitle}>{item.title}</h3>
                            <div className={style.pageBoxItemTopDevice}>
                                {item?.device?.map((ite, i) => (
                                    <img src={ite} />
                                ))}
                            </div>
                        </div>
                        <div className={style.pageBoxItemIntro}>
                            <h3 className={style.pageBoxItemIntroTitle}>{item.title}</h3>
                            <div className={style.pageBoxItemIntroContent}>
                                {item?.list?.length > 0 && item.list.map((children, idx) => (
                                    <div className={style.pageBoxItemIntroContentItem} key={idx}>
                                        {children?.length > 0 && children.map((child, i) => (
                                            <div className={style.pageBoxItemIntroContentItemBox} key={i}>
                                                <div className={style.pageBoxItemIntroContentItemBoxLabel}>{child.label}</div>
                                                <div className={style.pageBoxItemIntroContentItemBoxText} dangerouslySetInnerHTML={{ __html: child.text }}></div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                ))}

            </div>
        </div>
    )
}

export default Index