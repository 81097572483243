import React ,{useEffect,useRef} from 'react'
import style from './index.module.less'
import { 
    beauty_up,
    beauty_under
} from '@/images/air2/index'
import { gsap } from 'gsap'
import { useIntl } from "react-intl"
import { ScrollTrigger } from "gsap/ScrollTrigger";
const Beauty = ({ifShow}) =>{
    gsap.registerPlugin(ScrollTrigger)
    const imgBox = useRef()
    const upImgBox = useRef()
    const intl = useIntl()

    useEffect(()=>{
        gsap.to(upImgBox.current, {
            opacity: 0,
            scrollTrigger: {
                start: "top top",
                end: "bottom bottom",
                scrub: true,
                trigger: imgBox.current,
            }
        })
    },[])

    return(
        <div className={style.beauty}>
            <h2 className={style.beautyTitle}>
            {intl.formatMessage({ id: "air2.beauty.t1" })} <br/>{intl.formatMessage({ id: "air2.beauty.t2" })}
            </h2>
            <div className={style.beautyImg} ref={imgBox}>
                <div className={style.beautyImgBox} style={{backgroundImage:`url(${ifShow?beauty_under:""})`}}>
                    <div className={style.beautyImgBoxOn}
                    style={{backgroundImage:`url(${ifShow?beauty_up:""})`}} ref={upImgBox}
                    >
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Beauty