/**
 *midDevice传入是air2 还是 beam，切换中间设备图 
 */

import React ,{useRef,useState} from "react"
import style from './index.module.less'
import {
    picWindows,
    picMac,
    picAndroid,
    picIpad,
    picPS5,
    picSteam,
    picSwitch,
    picXbox,
    picP55
} from '@/images/air2/index'
import useBigScreen from "@/hooks/bigScreen"
import classNames from 'classnames'
import { isMobile } from '@/utils/index'
import { turn_beam } from '@/images/beam/index'
import { useIntl } from "react-intl"

const TurnDevices = ({ midDevice,ifShow=true }) => {
    const ifPc = useBigScreen(680)
    const ifMobile = isMobile()
    const intl = useIntl()

    const devicesData = [
        {
            img: picWindows,
            name: "Windows"
        },
        {
            img: picMac,
            name: "MacBook"
        },
        {
            img: picAndroid,
            name: "Android<br/>Devices"
        },
        {
            img: picIpad,
            name: "iPhone/iPad"
        },
        {
            img: midDevice == 'air2' ? picP55 : turn_beam,
            name: ""
        },
        {
            img: picSteam,
            name: "Steam Deck"
        },
        {
            img: picSwitch,
            name: "Nintendo<br/>Switch"
        },
        {
            img: picPS5,
            name: "PlayStation 5"
        },
        {
            img: picXbox,
            name: "Xbox"
        },
    ]
    const judgeTurn = (e)=>{
        const turnDiv=e.currentTarget.querySelector('.deviceItem')
        const turnDivStyle = window.getComputedStyle(turnDiv).getPropertyValue('transform')
        const matrixValues = turnDivStyle.split('(')[1].split(')')[0].split(',');
        const yRotationValue = parseFloat(matrixValues[0]);
   
        if(yRotationValue>0){
            turnDiv.style.transform=' rotateY(180deg) translateZ(0)'
        }else{
            turnDiv.style.transform=' rotateY(0) translateZ(0)'
        }
       
    }
    const reverse = (e)=>{
        const turnDiv=e.currentTarget.querySelector('.deviceItem')
        turnDiv.style.transform=' rotateY(0) translateZ(0)'
    }

    return (
        <div className={style.devices}>
            <h2 className={style.devicesTitle}>{intl.formatMessage({ id: "air2.Works.t" })}</h2>
            <div className={style.devicesDesc}>{intl.formatMessage({ id: `${midDevice == 'beam' ? "beam.Works.comments" : "air2.Works.comments"}` })}</div>
            {midDevice == 'air2' && <div className={style.devicesTip}>{intl.formatMessage({ id: "air2.Works.tips" })}</div>}

            <div className={style.devicesBox}>
                {devicesData.map((item, index) => (
                    <div key={`travelDevices${index}`} 
                    tabIndex='-1'
                    className={style.devicesBoxOut} 
                    onClick={(e)=> index !== 4&&ifMobile&&judgeTurn(e)}
                    onBlur={(e)=>index !== 4&&ifMobile&&reverse(e)}
                    > <div className={classNames(style.devicesBoxItem, index == 4 && style.devicesBoxItemCenter,'deviceItem')} style={{ backgroundColor: index == 4 ? "#fff" : "#f6f6f6" }}>
                            <div className={style.devicesBoxItemImg} style={{ backgroundImage: `url(${ifShow?item.img:""})` }}></div>
                            <div className={style.devicesBoxItemName} dangerouslySetInnerHTML={{ __html: item.name }}></div>
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}

export default TurnDevices
