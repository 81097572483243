import React, { useState, useEffect, useRef } from "react";
import classNames from 'classnames'
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
    engineBg
} from '@/images/air2/index'
import { useIntl } from "react-intl"

const Index = ({ifShow}) => {
    const intl = useIntl();
    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {
        gsap.to('.engineContent', {
            scrollTrigger: {
                trigger: '.engineBox',
                start: 'top 80%',
                end: 'top top',
                scrub: true,
            },
            width: '100%',
        });
    }, [])



    return (
        <div className={classNames(style.engine, 'engine')}>
            <h2 className={style.engineTitle}><span>{intl.formatMessage({ id: "air2.engine" })}</span><br />{intl.formatMessage({ id: "air2.withbest" })}</h2>
            <div className={classNames(style.engineBox, 'engineBox')}>
                <div className={classNames(style.engineContent, 'engineContent')} style={{ backgroundImage: `url(${ifShow?engineBg:""})` }}></div>
            </div>
        </div>
    )
}

export default Index