function isMobile() {
	let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	return flag;
}

function isIOS() {
	const ua = navigator.userAgent.toLowerCase();
	const isIPhone = ua.indexOf('iphone') > -1;
	const isIPad = ua.indexOf('ipad') > -1;
	const isIPod = ua.indexOf('ipod') > -1;
	if (isIPhone || isIPad || isIPod) return true;
	else return false;
}

function isAndroid() {
	const isBrowser = () => typeof window !== "undefined"
	const ua = isBrowser() ? window.navigator.userAgent.toLowerCase() : '';
	const ifAndroid = ua.indexOf('android') > -1;
	if (ifAndroid) return true;
	else return false;
}

export {
	isMobile,
	isIOS,
	isAndroid
};