import React from "react";
import style from './index.module.less'
import {
    lense_air2_white,
    lense_air2_logo1,
    lense_air2_logo2,
    lense_air2_logo3,
} from '@/images/air2/index'
import { useIntl } from "react-intl"


const Lense = ({ifShow}) => {
    const intl = useIntl()

    const lensesList = [
        {
            img: lense_air2_logo1,
            country: 'US',
            link: "https://frameofchoice.com/products/official-prescription-insert-for-xreal-air-2"
        },
        {
            img: lense_air2_logo2,
            country: 'EU&UK',
            link: "https://lensology.co.uk/xreal-air-2-ar-glasses/"
        },
        {
            img: lense_air2_logo3,
            country: 'JP',
            link: "https://junginza.com/xreal"
        },
    ]

    return (
        <div className={style.lense}>
            <h2 className={style.lenseTitle}>
                {intl.formatMessage({ id: "air2.eyehealth.t" })}
            </h2>
            <div className={style.lenseDesc}>            
            {intl.formatMessage({ id: "air2.eyehealth.comments" })}
            </div>
            <div className={style.lenseBox} style={{ backgroundImage: `url(${ifShow?lense_air2_white:""})` }}>
                {
                    lensesList.map((item, index) => (
                        <div className={style.lenseBoxItem}
                            key={`lensesItem${index}`}
                            onClick={() => window.open(item.link)}
                        >
                            <img src={item.img} />
                            <div className={style.lenseBoxItemCountry}>{item.country}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )

}
export default Lense
