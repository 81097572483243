import React from "react"
import style from './index.module.less'
import{
    upgrade1,
    upgrade2,
    upgrade3,
}from '@/images/air2/index'
import { useIntl } from "react-intl"


const Upgrade = ({ifShow}) =>{
    const intl = useIntl()
    const updateArr=[
        {
            img:upgrade1,
            desc:intl.formatMessage({ id: "air2.ergonomics.lightest" })
        },
        {
            img:upgrade2,
            desc:intl.formatMessage({ id: "air2.ergonomics.0pressure" })
        },
        {
            img:upgrade3,
            desc:intl.formatMessage({ id: "air2.ergonomics.weight" })
        }
    ]
    return(
        <div className={style.upgrade}>
            <h2 className={style.upgradeTitle}>{intl.formatMessage({ id: "air2.ergonomics.t" })} <br/><span>{intl.formatMessage({ id: "air2.ergonomics.subt" })}</span></h2>
            <div className={style.upgradeBox}>
                {updateArr.map((item,index)=>(
                    <div className={style.upgradeBoxItem} key={`updateBox${index}`}>
                        <div className={style.upgradeBoxItemImg} style={{backgroundImage:`url(${ifShow?item.img:""})`}}></div>
                        <div className={style.upgradeBoxItemDesc}>{item.desc}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Upgrade